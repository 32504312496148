// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-tsx": () => import("./../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instagram-tsx": () => import("./../src/pages/instagram.tsx" /* webpackChunkName: "component---src-pages-instagram-tsx" */),
  "component---src-pages-o-mne-tsx": () => import("./../src/pages/o-mne.tsx" /* webpackChunkName: "component---src-pages-o-mne-tsx" */),
  "component---src-pages-projekty-tsx": () => import("./../src/pages/projekty.tsx" /* webpackChunkName: "component---src-pages-projekty-tsx" */)
}

